import { useState, useEffect, useMemo } from 'react';
import { Box, Button } from "@mui/material";
import CoverText from '../../components/cover/coverText';
import BlockContent from '../../components/blockContent';
import ProjectList from '../../components/projectList';
import ContactForm from '../../components/contactForm';
import projectsData from "../../data/projects.json";
import { Project } from '../../types/type';
//img
import img1 from "../../assets/images/carousel/home/pexels-pixabay-269077.jpg";
import img2 from "../../assets/images/carousel/home/architecture-2178978_1280.jpg";
import img3 from "../../assets/images/carousel/home/pexels-pixabay-37347.jpg";
const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loadedImages, setLoadedImages] = useState<string[]>([]);
  const images = useMemo(() => [img1, img2, img3], [])

  useEffect(() => {
    let mounted = true;

    const loadImage = async (src: string): Promise<void> => {
      try {
        const img = new Image();
        await new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
          img.src = src;
        });

        if (mounted) {
          setLoadedImages(prev => [...prev, src]);
        }
      } catch (error) {
        console.error(`Failed to load image: ${src}`);
      }
    };

    // Load all images in parallel
    images.forEach(src => loadImage(src));

    return () => {
      mounted = false;
    };
  }, [images]);

  useEffect(() => {
    console.log("image carousel");

    if (loadedImages.length > 1) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % loadedImages.length);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [loadedImages]);

  const projects: Project[] = projectsData.projects.slice(0, 2);

  return (
    <Box>
      <Box sx={{ height: "100vh", position: "relative", overflow: "hidden" }}>
        {loadedImages.map((image, index) => (
          <Box
            key={index}
            component="img"
            src={image}
            alt={`Image ${index}`}
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              opacity: index === currentIndex ? 1 : 0,
              transition: "opacity 0.5s ease-in-out",
              objectFit: "cover"
            }}
          />
        ))}
      </Box>
      <CoverText text='ZUMAR-A CONSTRUCTION COMPANY WITH PRECISION ENGINNERING FOR SUPERIOR CONSTRUCTION' />
      <BlockContent title={'Vision'} paragraph={'Building innovative, sustainable and high-quality solutions that shape a better future'} />
      <BlockContent title={'mission'} paragraph={'To provide reliable and innovative construction services, ensuring every project is delivered on time with top tier quality and focus on exceeding client expectations'} bgColor='#F8F8F8' />
      <BlockContent title={'projects'} paragraph={'Explore our portfolio of completed projects that showcase our commitment to quality and dedication to craftmanship'} />
      <ProjectList projects={projects} />
      <Button href='/projects' variant="outlined" sx={{ color: 'black', fontSize: { xs: "1rem", md: "1.2rem" }, width: { xs: "30%", md: "10%" } }} id='projects-button'>
        PROJECTS
      </Button>
      <BlockContent title={'we innovate'} paragraph={'We have a proven track record in having you covered from structural design to execution and interior works'} bgColor='#F8F8F8' />
      <ContactForm />
    </Box>
  );
};

export default Home;